import React from 'react';
import styled from 'styled-components';
import { IRiderStats } from 'modules/reducers/riders';
import {values, nth} from 'lodash'

interface ILastPrice {
	is_price_up: boolean;
}

const LastPrice = styled.span<ILastPrice>`
	color: ${({is_price_up}) => is_price_up ? '#008108' : '#C80502'};
	font-size: 12px;
	font-family: "MotoGPText";
	font-weight: 500;
`;

interface IProps {
	prices: IRiderStats['prices']
}

export const PriceChange: React.FC<IProps> = ({prices}) => {
	const prices_arr = values(prices);
	const current = nth(prices_arr, -1);
	const previous = nth(prices_arr, -2);
	if (!previous || !current || current === previous) {
		return null
	}

	const is_price_up = current > previous;

	return (
		<LastPrice is_price_up={is_price_up}>
			({is_price_up && '+'}{(current - previous) / 1000}K)
		</LastPrice>
	)
};